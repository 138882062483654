html,
body,
div,
span,
h1,
h2,
h3,
p,
small,
form,
label,
article,
footer,
header,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
footer,
header,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  margin: 0;
  padding: 0;
  font-family: Avenir, Arial, Helvetica, sans-serif;
}
p {
  font-size: 20px;
  margin-block-end: 0.5em;
  line-height: 1.5;
}

h2 {
  font-size: 2em;
  margin-block-end: 0.5em;
  font-family: Dosis, Avenir, Arial, Helvetica, sans-serif;
  font-weight: 600;
}
